import * as THREE from 'three';
import { LAUNCHED_ITEM_ANIMATION_SPEED, LAUNCHED_ITEM_GEOMETRY, LAUNCHED_ITEM_Y, MANDALA_POSITION_Y, PLAYER_POSITION_Y, TEXTURE_ICON_SCISSORS, TILE_SPACING, TILE_STAIRS_OFFSET } from '../constants';

export class LaunchedScissors {
    constructor({ world, position, direction }) {
        this.world = world;
        this.position = position;
        this.direction = direction;
        this.mesh = new THREE.Mesh(
            LAUNCHED_ITEM_GEOMETRY,
            new THREE.MeshBasicMaterial({ map: TEXTURE_ICON_SCISSORS, transparent: true, side: THREE.DoubleSide })
        );
        this.mesh.position.set(
            this.position[0] * TILE_SPACING,
            LAUNCHED_ITEM_Y + this.position[1] * TILE_STAIRS_OFFSET,
            this.position[1] * TILE_SPACING
        );
        if (this.direction[0] === 1) {
            this.mesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2.0);
        } else if (this.direction[0] === -1) {
            this.mesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2.0);
        } else if (this.direction[1] === -1) {
            this.mesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI);
        }
        this.mesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2.0);
        this.world.scene.add(this.mesh);
        this.timer = 0.0;
        this.alive = true;
    }

    addToScene(scene) {
        scene.add(this.mesh);
    }

    removeFromScene(scene) {
        scene.remove(this.mesh);
    }

    worldPosition() {

        const t = Math.min(this.timer, 1.0);

        const nextPosition = [
            this.position[0] + this.direction[0],
            this.position[1] + this.direction[1]
        ];

        const interpolatedPosition = [
            this.position[0] * (1.0 - t) + nextPosition[0] * t,
            this.position[1] * (1.0 - t) + nextPosition[1] * t
        ];

        return [
            interpolatedPosition[0] * TILE_SPACING,
            LAUNCHED_ITEM_Y + interpolatedPosition[1] * TILE_STAIRS_OFFSET,
            interpolatedPosition[1] * TILE_SPACING
        ];

    }

    cutFlowerBelow() {
        const nextPosition = [
            this.position[0] + this.direction[0],
            this.position[1] + this.direction[1]
        ];

        const tile = this.world.tileAt(nextPosition);

        if (tile.flowerPoints > 0) {
            const nextPosition = [
                this.position[0] + this.direction[0],
                this.position[1] + this.direction[1]
            ];

            this.world.scene.remove(tile.flower);
            this.world.player.moves += tile.flowerPoints;
            tile.flower = null;
            tile.flowerPoints = 0;
            this.timer = 0.0;
            this.position = nextPosition;

            this.world.dispatchEvent("player-cut", null);
        } else {
            this.alive = false;
        }
    }

    update(dt) {
        this.timer += dt * LAUNCHED_ITEM_ANIMATION_SPEED;
        this.timer = Math.min(this.timer, 1.0);
        if (this.timer >= 1.0) {
            this.cutFlowerBelow();
        }

        const worldPosition = this.worldPosition();
        this.mesh.position.set(worldPosition[0], worldPosition[1], worldPosition[2]);
    }
}