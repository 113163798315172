{ "compressionlevel":-1,
 "editorsettings":
    {
     "export":
        {
         "format":"json",
         "target":"test8.json"
        }
    },
 "height":15,
 "infinite":false,
 "layers":[
        {
         "data":[64, 9, 4, 17, 3, 2, 2, 2, 2, 2, 2, 3, 2, 4, 64, 64, 2, 2, 2, 64, 64, 4, 64, 64, 64, 64, 64, 2, 4, 64, 64, 64, 64, 64, 64, 64, 4, 3, 2, 2, 2, 2, 2, 4, 64, 64, 4, 4, 4, 64, 64, 64, 2, 64, 64, 64, 64, 4, 2, 10, 64, 4, 4, 4, 64, 64, 8, 2, 2, 2, 2, 64, 64, 3, 64, 64, 4, 4, 4, 64, 64, 2, 64, 64, 64, 17, 2, 2, 2, 64, 64, 64, 64, 64, 64, 64, 2, 64, 64, 4, 4, 4, 4, 4, 64, 12, 3, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 17, 64, 4, 64, 64, 2, 4, 64, 64, 64, 64, 64, 64, 4, 4, 2, 64, 4, 64, 64, 5, 64, 64, 2, 15, 2, 2, 17, 2, 2, 2, 1, 4, 64, 64, 2, 64, 64, 64, 64, 4, 4, 64, 64, 64, 2, 64, 1, 17, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 3, 64, 64, 4, 64, 64, 64, 64, 64, 64, 2, 4, 4, 4, 4, 4, 64, 64, 64, 2, 3, 2, 2, 2, 2, 2, 64, 64, 64, 64, 64, 64, 64, 64, 11, 64, 64, 64, 4, 4, 4, 64, 64, 64, 64, 64, 64],
         "height":15,
         "id":1,
         "name":"tiles",
         "opacity":1,
         "type":"tilelayer",
         "visible":true,
         "width":15,
         "x":0,
         "y":0
        }],
 "nextlayerid":2,
 "nextobjectid":1,
 "orientation":"orthogonal",
 "renderorder":"right-down",
 "tiledversion":"1.6.0",
 "tileheight":32,
 "tilesets":[
        {
         "firstgid":1,
         "source":"tileset.tsx"
        }],
 "tilewidth":32,
 "type":"map",
 "version":"1.6",
 "width":15
}