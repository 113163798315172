import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { IMAGE_ICON_MUSIC_OFF, IMAGE_ICON_MUSIC_ON } from "../constants";

function MuteButton() {
    let [muted, setMuted] = useState(false);

    function onMute() {
        setMuted(true);
        document.querySelectorAll("audio").forEach((audio) => {
            audio.muted = true;
            audio.pause();
            window.isGameAudioMuted = true;
        });
        localStorage.setItem("game-audio-muted", "true");
    }

    function onUnmute() {
        setMuted(false);
        document.querySelectorAll("audio").forEach((audio) => {
            audio.muted = false;
            window.isGameAudioMuted = false;
        });
        localStorage.setItem("game-audio-muted", "false");
    }

    useEffect(
        () => {
            const mutedSetting = localStorage.getItem("game-audio-muted");
            if (mutedSetting === "true") {
                onMute();
            }
        },
        []
    );

    return <img src={muted ? IMAGE_ICON_MUSIC_OFF : IMAGE_ICON_MUSIC_ON}
        onClick={() => {
            if (muted) {
                onUnmute();
            } else {
                onMute();
            }
        }}></img>
}

export class MuteUI {
    constructor() {
        this.domElement = document.getElementById("mute-ui");
    }

    mount() {
        this.root = createRoot(this.domElement);
        this.root.render(<MuteButton />);
    }
}